import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import theme from 'src/utils/styles/theme'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { Trans } from 'gatsby-plugin-react-i18next'
import moment from 'moment-timezone'

import {
  CountdownSection,
  CountdownSectionContainer,
  CountDownContainer,
  Header,
  SecondaryHeader,
  Time,
  ColonWrapper,
  Label,
  LeftColumn,
  RightColumn,
  BuyNowContainer,
  BuyNowLink,
  Col,
  CalNYCol,
  Card,
  CalNYLabel,
  HideOnDesktop,
  HideOnMobile,
  DealHeadline,
  DealCard,
  DealContainer,
  DealTitle,
  DealPrice,
  ImageContainer,
  TextContainer,
  AmazonFlexContainer,
} from './styles'
import AmazonLink from '../Amazon'
import { BUY_LINK, CAL_BUY_LINK, LOCALIZATION } from '../../utils/constants'

function CalColumn(props) {
  const { ctaTimeColor, labelColor, content, label } = props
  return (
    <CalNYCol>
      <Card>
        <Time color={ctaTimeColor} isCalendarNY>
          {content}
        </Time>
      </Card>
      <CalNYLabel color={labelColor}>{label}</CalNYLabel>
    </CalNYCol>
  )
}

const CountDownTimer = ({
  holidayDate,
  page,
  unified,
  noBottomMargin,
  sticky,
  cal,
  amazonLink,
  onCountdownComplete,
}) => {
  const { unifiedFrame, unifiedCalendar, frame, calendar } = useStaticQuery(graphql`
    query CountDownTimerQuery {
      unifiedFrame: file(relativePath: { eq: "mothers/frame-deal.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      unifiedCalendar: file(relativePath: { eq: "mothers/calendar-deal.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      frame: file(relativePath: { eq: "mothers/frame.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      calendar: file(relativePath: { eq: "mothers/calendar.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
    }
  `)

  const [showDays, setShowDays] = useState('00')
  const [showHours, setShowHours] = useState('00')
  const [showMinutes, setShowMinutes] = useState('00')
  const [showSeconds, setShowSeconds] = useState('00')
  const [countdownCompleted, setCountdownCompleted] = useState(false)

  const convertToDoubleDigitString = (integer) => {
    if (integer > 9) return integer.toString()
    if (integer < 0) return `00`
    return `0${integer}`
  }

  const timeZones = {
    en: 'America/Los_Angeles', // PT
    'en-ca': 'America/New_York', // ET
    'en-au': 'Australia/Sydney', // AEDT
    'en-gb': 'Europe/London', // BST
    de: 'Europe/Berlin', // CEST
  }

  const targetTimeZone = timeZones[LOCALIZATION] || 'America/Los_Angeles' // Get the target timezone based on localization
  const countDownDate = moment.tz(holidayDate, targetTimeZone).valueOf() // Convert holiday date to target timezone

  useEffect(() => {
    const now = moment().valueOf()

    if (countDownDate - now <= 0) {
      setCountdownCompleted(true)
      onCountdownComplete()
    }
  }, [countDownDate, onCountdownComplete])

  useEffect(() => {
    const x = setInterval(() => {
      const now = moment().valueOf()
      const distance = countDownDate - now

      if (distance <= 0) {
        clearInterval(x)
        setCountdownCompleted(true)
        setShowDays('00')
        setShowHours('00')
        setShowMinutes('00')
        setShowSeconds('00')

        if (onCountdownComplete) {
          onCountdownComplete() // Notify that the countdown is complete
        }
      } else {
        const days = convertToDoubleDigitString(Math.floor(distance / (1000 * 60 * 60 * 24)))
        const hours = convertToDoubleDigitString(
          Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        )
        const minutes = convertToDoubleDigitString(
          Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
        )
        const seconds = convertToDoubleDigitString(Math.floor((distance % (1000 * 60)) / 1000))

        setShowDays(days)
        setShowHours(hours)
        setShowMinutes(minutes)
        setShowSeconds(seconds)
      }
    }, 1000)

    return () => clearInterval(x)
  }, [countDownDate, onCountdownComplete])

  if (countdownCompleted) {
    return null
  }

  const HOLIDAY = {
    unifiedMom: {
      header: `MOTHER'S DAY SALE ENDS IN`,
      mobileHeader: `MOTHER'S DAY SALE`,
      backgroundColor: theme.pink,
      ctaColor: '#B86AA8',
      ctaTextColor: 'white',
      ctaText: 'Shop Deals',
      calCtaLink: CAL_BUY_LINK,
      frameCtaLink: BUY_LINK,
    },
    frameMom: {
      header: `MOTHER'S DAY SALE`,
      mobileHeader: `MOTHER'S DAY SALE`,
      backgroundColor: theme.pink,
      ctaColor: '#B86AA8',
      ctaTextColor: 'white',
      ctaText: 'Shop Deals',
      ctaLink: BUY_LINK,
      quote: '“My Mom says this is the best gift she’s ever received.”',
      'en-au': 'My Mum says this is the best gift she’s ever received.',
      image: frame,
    },
    calendarMom: {
      header: `MOTHER'S DAY SALE`,
      mobileHeader: `MOTHER'S DAY SALE`,
      backgroundColor: theme.pink,
      ctaColor: '#B86AA8',
      ctaTextColor: 'white',
      ctaText: 'Shop Deals',
      ctaLink: CAL_BUY_LINK,
      quote: '“Greatest gift for a busy mom! Works great, kids love it, wife loves it.”',
      image: calendar,
    },
    prime: {
      header: `Shop Prime Big Deal Days - On Now!`,
      backgroundColor: '#0578FF',
      ctaText: 'Shop now at Amazon.com',
      ctaColor: '#FFF',
      ctaTextColor: 'white',
      ctaTimeColor: '#FFF',
      labelColor: '#FFF',
    },
  }

  const timeDisplay = [
    { content: showDays, label: 'DAYS' },
    { content: showHours, label: 'HRS' },
    { content: showMinutes, label: 'MINS' },
    { content: showSeconds, label: 'SECS' },
  ]
  if (countdownCompleted) {
    return null
  }
  const countdownDetails = HOLIDAY[page]

  return (
    <CountdownSection
      cal={cal}
      backgroundColor={countdownDetails.backgroundColor}
      sticky={sticky}
      unified={unified}
    >
      <CountdownSectionContainer noBottomMargin={noBottomMargin}>
        <LeftColumn>
          {countdownDetails.header && (
            <Header titleColor={countdownDetails.titleColor}>
              Shop Prime Big Deal Days -{' '}
              <span>
                {' '}
                <Trans>On Now!</Trans>
              </span>
            </Header>
          )}
          <SecondaryHeader>
            <Trans>EXCLUSIVELY ON AMAZON.COM</Trans>
          </SecondaryHeader>
          <AmazonFlexContainer>
            <CountDownContainer>
              {timeDisplay.map((time, i) => (
                <>
                  <Col>
                    <Time color={countdownDetails.ctaTimeColor}>{time.content}</Time>
                    <Label>
                      <Trans>{time.label}</Trans>
                    </Label>
                  </Col>
                  {i < timeDisplay.length - 1 && (
                    <ColonWrapper>
                      <Time color={countdownDetails.ctaTimeColor}>:</Time>
                    </ColonWrapper>
                  )}
                </>
              ))}
            </CountDownContainer>
            <AmazonLink link={amazonLink} />
          </AmazonFlexContainer>
          {unified && (
            <>
              <HideOnMobile>
                <DealHeadline>
                  <span>SAVE UP TO $50</span> ON PERFECT GIFTS FOR MOM
                </DealHeadline>
                <BuyNowContainer>
                  <BuyNowLink
                    bgColor="#ED9D9C"
                    color={theme.darkGray}
                    to={countdownDetails.calCtaLink}
                    marginRight
                  >
                    Shop Calendars
                  </BuyNowLink>
                  <BuyNowLink
                    bgColor="#ED9D9C"
                    color={theme.darkGray}
                    to={countdownDetails.frameCtaLink}
                  >
                    Shop Frames
                  </BuyNowLink>
                </BuyNowContainer>
              </HideOnMobile>
              <HideOnDesktop>
                {/* <Header>Top Deals</Header> */}
                <DealContainer>
                  <DealCard>
                    <DealTitle>15" Calendar </DealTitle>
                    <Link to={countdownDetails.calCtaLink}>
                      <GatsbyImage
                        image={unifiedCalendar.childImageSharp.gatsbyImageData}
                        alt="Skylight Calendar on week mode"
                      />{' '}
                    </Link>
                    <p />
                    {/* <DealPrice>
                      $299.99 <span>$249.99</span>
                    </DealPrice> */}
                    <BuyNowContainer>
                      <BuyNowLink
                        bgColor="#ED9D9C"
                        color={theme.darkGray}
                        to={countdownDetails.calCtaLink}
                      >
                        Shop Calendars
                      </BuyNowLink>
                    </BuyNowContainer>
                  </DealCard>
                  <DealCard>
                    <DealTitle>10" Black Frame</DealTitle>
                    <Link to={countdownDetails.frameCtaLink}>
                      <GatsbyImage
                        image={unifiedFrame.childImageSharp.gatsbyImageData}
                        alt="Skylight Frame shown on portrait with a mom and child embracing with pink flowers"
                      />
                    </Link>
                    <p />
                    {/* <DealPrice>
                      $159.99 <span>$199.99</span>
                    </DealPrice> */}
                    <BuyNowContainer>
                      <BuyNowLink
                        bgColor="#ED9D9C"
                        color={theme.darkGray}
                        to={countdownDetails.frameCtaLink}
                      >
                        Shop Frames
                      </BuyNowLink>
                    </BuyNowContainer>
                  </DealCard>
                </DealContainer>
              </HideOnDesktop>
            </>
          )}
        </LeftColumn>
        {!unified && page !== 'prime' && (
          <RightColumn>
            <ImageContainer cal={cal}>
              <GatsbyImage
                image={countdownDetails.image.childImageSharp.gatsbyImageData}
                alt="Skylight Frame shown on portrait with a mom and child embracing with pink flowers"
              />
            </ImageContainer>
            <TextContainer>
              <SecondaryHeader>
                {countdownDetails[LOCALIZATION] || countdownDetails.quote}
              </SecondaryHeader>
              <BuyNowContainer>
                <BuyNowLink
                  big
                  bgColor="#ED9D9C"
                  color={theme.darkGray}
                  to={countdownDetails.ctaLink}
                >
                  {countdownDetails.ctaText}
                </BuyNowLink>
              </BuyNowContainer>
            </TextContainer>
          </RightColumn>
        )}
      </CountdownSectionContainer>
    </CountdownSection>
  )
}

CountDownTimer.propTypes = {
  holidayDate: PropTypes.string.isRequired,
  page: PropTypes.string,
  unified: PropTypes.bool,
  noBottomMargin: PropTypes.bool,
  sticky: PropTypes.bool,
  cal: PropTypes.bool,
  amazonLink: PropTypes.string,
  onCountdownComplete: PropTypes.func,
}
CalColumn.propTypes = {
  ctaTimeColor: PropTypes.string,
  labelColor: PropTypes.string,
  content: PropTypes.any,
  label: PropTypes.string,
}
export default CountDownTimer
